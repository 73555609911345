/* eslint-disable */
/*
     匹配 ) 或 . 或 + 或 - 或 _ 或 $ 或 ]
     */
let validDivisionCharRE = /[\w).+\-_$\]]/;
/*
      生成过滤器的 表达式字符串
      如上面的
      exp = message
      filters = ['filterA','filterB(arg1,arg2)']
      第一步  以exp 为入参 生成 filterA 的过滤器表达式字符串  _f("filterA")(message)
      第二步 以第一步字符串作为入参 生成第二个过滤器的表达式字符串 _f("filterB")(_f("filterA")(message),arg1,arg2)
      => _f("filterB")(_f("filterA")(message),arg1,arg2)
      * @param {string} exp   上一个过滤器的值 没有就是 表达式的值
      * @param {string} filter
      * @returns {string}
      */
//    //console.log(wrapFilter('abc','defg(hijk)')) //结果 _f("defg")(abc,hijk)
function wrapFilter(exp, filter) {
  let i = filter.indexOf("("); // 返回字符串第一次出现索引的位置
  // console.log("i=" + i);
  if (i < 0) {
    // _f: resolveFilter
    return '_f("' + filter + '")(' + exp + ")"; // 闭包
  } else {
    // name 是 从字符串开始到(结束的字符串,不包含(
    let name = filter.slice(0, i); // 截取字符串 arrayObject.slice(start,end)
    // console.log("==name==");
    // console.log(name);

    // args是从(开始匹配，到字符串末端，不包含(
    let args = filter.slice(i + 1); // 如果 end 未被规定，那么 slice() 方法会选取从 start 到数组结尾的所有元素。
    // console.log("==args==");
    // console.log(args);
    return '_f("' + name + '")(' + exp + (args !== ")" ? "," + args : args);
  }
}

/* 处理value 解析成正确的value，把过滤器 转换成vue 虚拟dom的解析方法函数
 * 表达式中的过滤器解析 方法
 * @param {*} exp
 */
// eslint-disable-next-line complexity
export default function parseFilters(exp) {
  // 是否在 ''中
  let inSingle = false;
  // 是否在 "" 中
  let inDouble = false;
  // 是否在 ``
  let inTemplateString = false;
  //  是否在 正则 \\ 中
  let inRegex = false;
  // 是否在 {{ 中发现一个 culy加1 然后发现一个 } culy减1 直到culy为0 说明 { .. }闭合
  let curly = 0;
  // 跟{{ 一样 有一个 [ 加1 有一个 ] 减1
  let square = 0;
  // 跟{{ 一样 有一个 ( 加1 有一个 ) 减1
  let paren = 0;
  let lastFilterIndex = 0;
  let c;
  let prev;
  let i;
  let expression;
  let filters;

  for (i = 0; i < exp.length; i++) {
    prev = c;
    c = exp.charCodeAt(i);
    // console.log("c =" + exp[i]);
    // console.log("c === 0x7C=" + (c === 0x7c));
    // console.log(
    //   "exp.charCodeAt(i + 1) !== 0x7C=" + (exp.charCodeAt(i + 1) !== 0x7c)
    // );
    // console.log(
    //   "exp.charCodeAt(i - 1) !== 0x7C=" + (exp.charCodeAt(i - 1) !== 0x7c)
    // );
    // console.log("curly=" + curly);
    // console.log("!curly=" + !curly);
    // console.log("square=" + square);
    // console.log("!square=" + !square);
    // console.log("!paren=" + !paren);
    // console.log(
    //   "最后一个=" +
    //     (c === 0x7c && // pipe
    //       exp.charCodeAt(i + 1) !== 0x7c &&
    //       exp.charCodeAt(i - 1) !== 0x7c &&
    //       !curly &&
    //       !square &&
    //       !paren)
    // );

    if (inSingle) {
      //  '  \
      if (c === 0x27 && prev !== 0x5c) {
        inSingle = false;
      }
    } else if (inDouble) {
      // " \
      if (c === 0x22 && prev !== 0x5c) {
        inDouble = false;
      }
    } else if (inTemplateString) {
      //  `
      if (c === 0x60 && prev !== 0x5c) {
        inTemplateString = false;
      }
    } else if (inRegex) {
      // 当前在正则表达式中  /开始
      //  / \
      if (c === 0x2f && prev !== 0x5c) {
        inRegex = false;
      }
    } else if (
      c === 0x7c && // |
      exp.charCodeAt(i + 1) !== 0x7c &&
      exp.charCodeAt(i - 1) !== 0x7c &&
      !curly &&
      !square &&
      !paren
    ) {
      /*
      如果前面没有表达式那么说明这是第一个 管道符号 "|"
      再次遇到 | 因为前面 expression = 'message '
      执行  pushFilter()
      */
      if (expression === undefined) {
        // first filter, end of expression
        // 过滤器表达式 就是管道符号之后开始
        lastFilterIndex = i + 1;
        // 存储过滤器的 表达式
        expression = exp.slice(0, i).trim(); // 这里匹配如果字符串是 'ab|c' 则把ab匹配出来

        // console.log(expression);
        // debugger;
      } else {
        pushFilter();
      }
    } else {
      switch (c) {
        case 0x22:
          inDouble = true;
          break; // 匹配"
        case 0x27:
          inSingle = true;
          break; // 匹配'
        case 0x60:
          inTemplateString = true;
          break; // 匹配`
        case 0x28:
          paren++;
          break; // 匹配(
        case 0x29:
          paren--;
          break; // 匹配)
        case 0x5b:
          square++;
          break; // 匹配[
        case 0x5d:
          square--;
          break; // 匹配]
        case 0x7b:
          curly++;
          break; // 匹配 {
        case 0x7d:
          curly--;
          break; //  匹配 }
        case 0x5c:
          break; //   匹配   \
        case 0x2f:
          break; //   匹配   /

        case 0x7c: //   匹配   |
          break;
      }

      if (c === 0x2f) {
        //  /
        let j = i - 1;
        // eslint-disable-next-line no-void
        let p = void 0;
        // find first non-whitespace prev char
        // 查找第一个非空白的prev字符
        for (; j >= 0; j--) {
          p = exp.charAt(j);
          if (p !== " ") {
            break;
          }
        }
        if (!p || !validDivisionCharRE.test(p)) {
          inRegex = true;
        }
      }
    }
  }

  if (expression === undefined) {
    expression = exp.slice(0, i).trim();
  } else if (lastFilterIndex !== 0) {
    pushFilter();
  }
  // 获取当前过滤器的 并将其存储在filters 数组中
  //  filters = [ 'filterA' , 'filterB']
  function pushFilter() {
    (filters || (filters = [])).push(exp.slice(lastFilterIndex, i).trim());
    lastFilterIndex = i + 1;
  }

  if (filters) {
    // console.log(filters);
    for (i = 0; i < filters.length; i++) {
      // 把过滤器封装成函数 虚拟dom需要渲染的函数
      expression = wrapFilter(expression, filters[i]);
    }
  }
  // console.log(expression);
  // 返回值
  return expression;
}
