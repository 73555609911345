<template>
  <div v-if="PageScript" class="container">
    <div v-for="formItem in pageLayout" :key="formItem.fmCode" class="form h-shadow-sm">
      <div class="form-title">
        <span>{{ formItem.fmName }}</span>
      </div>
      <div class="form-content">
        <HYFormView :ref="formItem.fmCode + 'FormRef'" :config="formItem" :form="pageForm[formItem.fmCode]"> </HYFormView>
      </div>
    </div>
  </div>
</template>
<script>
import HYFormView from "@/components/FormView.vue";
import layoutData from "@/views/product/layout/orderData";
import scriptLib from "@/views/product/layout/orderScript";
import dayjs from "dayjs";
import { mapState } from "vuex";
export default {
  name: "ProductContractDetail",
  components: { HYFormView },
  provide() {
    return {
      pageContext: this,
      pageForm: this.pageForm,
      pageLayout: this.pageLayout,
      pageLayoutMap: this.pageLayoutMap,
      PageScript: this.PageScript
    };
  },
  data() {
    return {
      productData: null,
      pageForm: {},
      pageLayout: [],
      pageLayoutMap: {},
      PageScript: scriptLib.call(this)
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("user", ["info"]),
    scriptObj() {
      const pageScriptObj = new this.PageScript();
      pageScriptObj.pageForm = this.pageForm;
      pageScriptObj.pageLayout = this.pageLayout;
      pageScriptObj.pageLayoutMap = this.pageLayoutMap;
      pageScriptObj.pageContext = this;
      return pageScriptObj;
    }
  },
  async created() {
    await this.initData();
    this.productData = await layoutData.call(this);
    this.init();
  },
  methods: {
    dayjs,
    init() {
      const { id } = this.$route.query;
      this.scriptObj.getContract(id);
    },
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
::v-deep .van-checkbox {
  display: inline-block;
}
.form {
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
  .form-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    span {
      border-left: 5px solid $primary-color;
      padding: 2px 8px;
    }
  }
}
.fail-reason {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: 微软雅黑;
  color: rgb(102, 102, 102);
  line-height: 30px;
  padding: 0px 20px;
  word-wrap: break-word;
  padding-bottom: 20px;
}
.submitBtn {
  height: 44px;
  .fixed {
    border-top: 1px solid $border-color;
    position: fixed;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.agreement ::v-deep {
  line-height: 12px;
  .van-checkbox__icon--disabled .van-icon {
    background-color: #fff;
  }
  .van-checkbox__icon--checked .van-icon {
    color: #fff;
    border-color: #57ccc2;
    background-color: #57ccc2;
  }
}
</style>
